import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {MatButtonModule} from '@angular/material/button'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MatDialogModule} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {MatProgressBar} from '@angular/material/progress-bar'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {authInterceptor} from './application/auth.interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {responseInterceptor} from './application/response.interceptor'
import {HeaderComponent} from './components/header/header.component'
import {WaitComponent} from './components/wait/wait.component'
import {ConfigService} from './services/config.service'
import {LoanService} from './services/loan.service'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WaitComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBar], providers: [
    // The Config service depends on the SSO Service and the
    // APP_INITIALIZER requires the ConfigService
    LOCAL_STORAGE_PROVIDERS,
    LoanService,
    SingleSignOnService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor]))
  ]
})
export class AppModule {
}
