{
  "name": "ll-admin-fe",
  "version": "2.3.2-da808604.0",
  "scripts": {
    "ng": "ng",
    "install": "cp ./package.json ./src/assets",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "lint": "ng lint",
    "style-lint": "stylelint \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.7",
    "@angular/cdk": "^18.2.7",
    "@angular/common": "^18.2.7",
    "@angular/compiler": "^18.2.7",
    "@angular/core": "^18.2.7",
    "@angular/forms": "^18.2.7",
    "@angular/material": "^18.2.7",
    "@angular/platform-browser": "^18.2.7",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/router": "^18.2.7",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.7",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "^18.2.7",
    "@angular/compiler-cli": "^18.2.7",
    "@angular/language-service": "^18.2.7",
    "@angular/material-luxon-adapter": "^18.2.7",
    "@sparbanken-syd/loan-backend": "^3.4.0",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@sparbanken-syd/user-documents-backend": "^1.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/jmespath": "^0",
    "@types/luxon": "^3",
    "@types/node": "^20.11.26",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "jmespath": "^0.16.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.4",
    "postcss": "^8.4.32",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.2.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.4.5"
  }
}
