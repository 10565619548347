<div class="main">
  <spb-header></spb-header>
  <div class="spb-holder">
    <router-outlet></router-outlet>
  </div>
  <div [style.flex]="1"></div>
  <spb-footer
    (logoutUser)="configService.logout()"
    [license]="true"
    [token]="(configService.accessToken$ | async)"
    [version]="version"
    copyrightYears="2019-2024"
  ></spb-footer>
</div>