import {Component} from '@angular/core'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly canUseTestingTools = !environment.production

  constructor(
    public configService: ConfigService
  ) {
  }

  /**
   * Turns UC mocking on or off.
   */
  public toggleUC(event: Event): void {
    event.stopPropagation()
    this.configService.isMockUcActive$.update(value => !value)
  }
}
